<template>
  <sales-view
    v-if="viewModeIsSales"
    :contact="contact"
    @changeViewMode="toggleViewMode"
  />
  <accounts-view
    v-else
    :contact="contact"
    :comments="comments"
    @changeViewMode="toggleViewMode"
  />
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const SalesView = () => import("@/components/Contacts/SalesView.vue");
const AccountsView = () => import("@/components/Contacts/AccountsView.vue");

export default {
  name: "EditContact",
  props: {
    contactId: String,
  },
  components: {
    PageHeader,
    SalesView,
    AccountsView,
  },
  data() {
    return {
      viewModeIsSales: false,
      contact: null,
      comments: null,
    };
  },
  async mounted() {
    try {
      let result = await this.ContactService.getContactById(this.contactId);
      this.contact = result;

      let resultComments = await this.CommentService.getCommentByResourceIdAndTypeId(
        this.contactId,
        "contact"
      );
      this.comments = resultComments.data;

      console.log(this.contact);
    } catch (error) {
      console.error(error.response);
    }
  },
  methods: {
    toggleViewMode: function() {
      this.viewModeIsSales = !this.viewModeIsSales;
    },
  },
};
</script>
